import { useContext } from 'react';
import ModalsServiceContext from './ModalsServiceContext';
import { ModalsApi } from './types';

const useModalsService = (): ModalsApi => {
  const context = useContext(ModalsServiceContext);

  if (!context) {
    throw new Error('ModalsContext is used without provider');
  }

  return context;
};

export default useModalsService;
