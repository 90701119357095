'use client';

import { Fragment, ReactNode } from 'react';
import { Header } from 'src/modules/header';
import Footer from '../components/Footer/Footer';
import { tablet_desktop } from '../styles/media';
import { HEADER_HEIGHT, HEADER_HEIGHT_PHONE } from 'src/data/constants';
import NotificationsWrapper from 'src/modules/notifications';

interface Props {
  children?: ReactNode;
}

const GeneralPagesLayout = (props: Props) => {
  const { children } = props;

  return (
    <Fragment>
      <Header />
      <NotificationsWrapper />
      <main>
        {children}
      </main>
      <Footer />
      <style jsx>{`
        main {
          margin-top: ${HEADER_HEIGHT_PHONE}px;
        }
        @media ${tablet_desktop} {
          main {
            margin-top: ${HEADER_HEIGHT}px;
          }
        }
      `}</style>
    </Fragment>
  );
};

export default GeneralPagesLayout;
